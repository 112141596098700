<template>
  <div>
    
    <div>
      <b-table
        :items="table.items"
        :fields="$t('clan')=='en'?table.fields:tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >


     <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>


        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="showModal(row.item.id)" v-if="type !== 'agent'">
              <i class="fas fa-check"></i>


              {{$t('Approve')}}
            </button>
            <button
              @click.prevent="showModal2(row.item.id)"
              class="ml-5"
              v-if="type !== 'agent'"
            >
              <i class="fas fa-ban mr-2"></i>{{$t('Reject')}}
            </button>
          </div>
        </template>


       

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link" @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link" @click="next">{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      title="Approve Transaction"
      hide-footer
    >
      <p>
        by click on approve this transaction will be approved
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="success" @click.prevent="Approve(tempId)">Approve</b-button>
      </div>
    </b-modal>


    <b-modal
      id="modal-2"
      modal-class="modal"
      ref="my-modal2"
      title="Reject Transaction"
      hide-footer
    >
      <p>
        by click on approve this transaction will be rejected,are you sure?
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal2"
          >Cancel</b-button
        >
        <b-button variant="danger" @click.prevent="Reject(rejectID)">Reject</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
export default {
  name: "TarrifTable",
  async mounted() {
    this.isBusy = true
    this.$store.dispatch(VERIFY_AUTH).then(res =>{
      var data = res.data[0]
      //console.log(data.type)
      this.type = data.type
      //console.log(this.type)
      })
    await this.$store.dispatch("transactions/loadAll").then((res) => {
      this.totalRows = res.data.data.total;
      this.lastPage = res.data.data.last_page
      this.perPage = res.data.data.per_page
      this.isBusy = false
    });
  },

  computed: {
    ...mapGetters({ table: "transactions/table" }),
    ...mapGetters({ tableAR: "transactions/tableAR" }),
  },
  data: function () {
    return {
      type:'',
     isBusy:'',
      approval:{
          status:'',
          transaction_id:''
      },
      rejectID:null,
      tempId: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:'',
      
    };
  },
  methods: {
     async Approve(id){
          this.approval.transaction_id = id
          this.approval.status='completed'
         await this.$store.dispatch('transactions/Approval',this.approval)

          
          //console.log(this.approval)
          

     this.hideModal()
     Swal.fire({
          title: "",
          text: "Approved Successfully" ,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
         this.$store.dispatch("transactions/loadAll").then((res) => {
           // console.log(res)
      this.totalRows = res.data.data.total;
      this.lastPage = res.data.data.last_page
      this.table.items = res.data.data.data
     this.getData()
    });
      },
     async Reject(id){
          this.approval.transaction_id = id
          this.approval.status='rejected'
         await this.$store.dispatch('transactions/Approval',this.approval)
          //console.log(this.approval)'
          
    this.hideModal2()
    Swal.fire({
          title: "",
          text: "Rejected Successfully" ,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$store.dispatch("transactions/loadAll").then((res) => {
      this.totalRows = res.data.data.total;
      this.lastPage = res.data.data.last_page
      this.getData()
    });
      },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      await this.$store.dispatch("transactions/loadPage", this.currentPage);
    },
    /*viewQueue(id) {
      this.$router.push({ name: "EditTarrifClients", params: { id: id } });
    },*/
    /*async deleteTarrif() {
      //this.tarrif.id = this.tempId;
      var tarrif = {id:this.tempId}
      await this.$store.dispatch("clientTarrif/delete", tarrif);
      await this.$store.dispatch("clientTarrif/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },*/

    showModal(id) {
        //console.log(id)
      this.$refs["my-modal"].show();
      this.tempId = id;
    },
    showModal2(id) {
        //console.log(id)
      this.$refs["my-modal2"].show();
      this.rejectID = id;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideModal2() {
      this.$refs["my-modal2"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>